import React from "react";
import {Flex, Image} from "@chakra-ui/react";
import EatingLocationCard from "../../components/EatingLocationCard/EatingLocationCard";

export default ({handleEatingLocationClick}: {handleEatingLocationClick: Function}) => {
    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'50%'} mx={'auto'}>
        <Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'}  mb={16} />
        <Flex justifyContent={'space-around'} flexWrap={'wrap'} flexDirection={'row'} gap={3} >
            <EatingLocationCard text={'Подигни од ресторанот'} iconPath={'/images/bag.webp'} callbackClick={() => handleEatingLocationClick('takeaway')}/>
            <EatingLocationCard text={'Јади во ресторанот'} iconPath={'/images/eat-in.jpg'} callbackClick={() => handleEatingLocationClick('eatIn')}/>
        </Flex>

    </Flex>
}