import EatingLocationView from "./EatingLocationView";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate} from "react-router-dom";
import {selectEatingLocation, selectLocation} from "../../reducers/locations";

export default () => {
    const dispatch = useAppDispatch();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    const handleEatingLocationClick = (location:string) => {
        dispatch(selectEatingLocation(location));
        navigate(`/${locationsState.selectedLocation.locationId}/menu`)
    }
    return <EatingLocationView handleEatingLocationClick={handleEatingLocationClick}/>
}