import {Flex, Text, Image, Heading} from "@chakra-ui/react";
import LocationCard from "../../components/LocationCard/LocationCard";
import React from "react";

export default ({locations, handleLocationClick}: {locations: any[], handleLocationClick: Function}) => {

    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'50%'} mx={'auto'}>
        <Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'}  mb={16} />
        <Heading as={'h1'} textAlign={'center'}  mb={32} >Одберете локација</Heading>
        <Flex justifyContent={'space-around'} flexWrap={'wrap'} flexDirection={'row'} >
            {locations.map(location => <LocationCard key={location.locationId} location={location} callbackClick={handleLocationClick}/> )}
        </Flex>

    </Flex>
}