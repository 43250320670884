import {Flex, Text, Image, Heading, Button} from "@chakra-ui/react";
import LocationCard from "../../components/LocationCard/LocationCard";
import React from "react";

export default ({orderId, handleGoBack}: {orderId: string, handleGoBack: Function}) => {

    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'50%'} mx={'auto'}>
        <Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'}  mb={16} />
        <Heading as={'h1'} textAlign={'center'}  mb={32} >Нарачката е креирана успешно. Нашиот тим ќе Ве исконтактира кога нарачката ќе биде готова.</Heading>
        <Heading as={'h1'} textAlign={'center'}  mb={32} >{`Код за идентификација: ${orderId}.`}</Heading>
        <Button onClick={() => handleGoBack()}>Врати се на почеток</Button>
    </Flex>
}