import {Button, Flex, Heading, IconButton, Image, Text} from "@chakra-ui/react";
import LocationCard from "../../components/LocationCard/LocationCard";
import React from "react";
import {AddIcon, MinusIcon} from "@chakra-ui/icons";
import {changeItemQuantity} from "../../reducers/locations";
import {useAppDispatch} from "../../hooks";

export default ({order, handleCancelOrder, handleOverviewConfirm}: {order: any[], handleCancelOrder:Function, handleOverviewConfirm:Function}) => {
    const dispatch = useAppDispatch()
    const totalPrice = order.reduce((acc, item) => {
        const itemPrice = item?.price ? +item?.price : 0
        const extrasPrice = item.extras.reduce(
            (acc: number, currentExtra: any) => acc + +currentExtra.price,
            0
        )
        return acc + (itemPrice + extrasPrice) * item.quantity
    }, 0)
    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'50%'} mx={'auto'}>
        <Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'}  mb={16} />
        <Heading as={'h2'}  mb={32} >Вашата нарачка:</Heading>
        <Flex justifyContent={'space-around'} flexWrap={'wrap'} flexDirection={'column'} >
            {order.map((orderItem, ind) => <Flex flexDirection={'column'} borderBottom={'1px'} mb={4} pb={2}>
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                    <Flex alignItems={'center'}>
                        <Image src={orderItem.image} height={"50px"} mr={4}/> <Text>{orderItem.name}</Text>
                    </Flex>
                    <Flex alignItems={'center'}>
                        <IconButton
                            boxSize={6}
                            isRound={true}
                            colorScheme='blue'
                            aria-label='Decrease quantity'
                            onClick={() => dispatch(changeItemQuantity({ind,qty: -1}))}
                            icon={<MinusIcon />}
                        />
                        <Text p={3}>
                            {orderItem.quantity}
                        </Text>

                        <IconButton
                            boxSize={6}
                            isRound={true}
                            colorScheme='blue'
                            aria-label='Increase quantity'
                            onClick={() => dispatch(changeItemQuantity({ind,qty: 1}))}
                            icon={<AddIcon />}
                        />
                    </Flex>

                </Flex>
                <Flex pl={4} flexDirection={'column'}>
                    {orderItem.extras.map((extra:any) => <Flex alignItems={'center'}>
                        <Image src={extra.image} height={"25px"}  mr={4}/> <Text>{extra.name}</Text>
                    </Flex>)}
                </Flex>
            </Flex>)}
        </Flex>
        <Heading as={'h5'}  mb={32} >Вкупно за наплата: {totalPrice} денари</Heading>

    </Flex>
}