import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex, Image,
    Text,
    Input, useDisclosure
} from "@chakra-ui/react";
import React, {useState} from "react";
import EatingLocationCard from "../../components/EatingLocationCard/EatingLocationCard";
import MenuItems from "./components/MenuItems/MenuItems";
import ItemEditing from "./components/ItemEditing/ItemEditing";
import {addProductToOrder} from "../../reducers/locations";
import OrderInfo from "../OrderInfo/OrderInfo";
import OrderOverview from "../OrderOverview/OrderOverview";

export const SUB_MENU = {
    FOOD: 'FOOD',
    DRINK: 'DRINK',
    DESSERT: 'DESSERT',
    COMBO: 'COMBO',
    SEASONAL: 'SEASONAL'
}
export default ({food, drink, meat, sauce, topping, sides, selectItem, selectedItem, addItemToOrder, clearItem, order, dessert, combo, seasonal, handleCancelOrder, handleConfirmOrder}: {food: any[], drink: any[], meat: any[], sauce: any[], topping: any[], sides: any[], selectItem: Function, selectedItem: null | any, addItemToOrder: Function, clearItem: Function, order: any[], dessert: any[], combo: any[], seasonal: any[],handleCancelOrder: Function, handleConfirmOrder: Function}) => {
    const [selectedSubMenu, setSelectedSubMenu] = useState(SUB_MENU.FOOD);
    const [isOpen, setIsOpen] = useState(false);
    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} w={'100%'} mx={'auto'}>
        <Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'}  mb={16} />
        <Flex gap={10}>
            <Flex direction={'column'} gap={5} width={'100px'} minW={'100px'}>
                <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} opacity={selectedSubMenu === SUB_MENU.FOOD ? 1: 0.5}>
                    <Image  cursor={'pointer'} src={'/images/taco-icon.png'} height={50} onClick={() => setSelectedSubMenu(SUB_MENU.FOOD)}/>
                    <Text textAlign={'center'}>Такос</Text>
                </Flex>
                <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}  opacity={selectedSubMenu === SUB_MENU.DRINK ? 1: 0.5}>
                    <Image cursor={'pointer'} src={'/images/drink.webp'} height={50}  onClick={() => setSelectedSubMenu(SUB_MENU.DRINK)}/>
                    <Text textAlign={'center'}>Пијалоци</Text>
                </Flex>
                {!!dessert?.length && <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}  opacity={selectedSubMenu === SUB_MENU.DESSERT ? 1: 0.5}>
                    <Image cursor={'pointer'} src={'/images/dessert.png'} height={50}  onClick={() => setSelectedSubMenu(SUB_MENU.DESSERT)}/>
                    <Text textAlign={'center'}>Десерти</Text>
                </Flex>}
                {!!combo?.length && <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}  opacity={selectedSubMenu === SUB_MENU.COMBO ? 1: 0.5}>
                    <Image cursor={'pointer'} src={'/images/food.png'} height={50}  onClick={() => setSelectedSubMenu(SUB_MENU.COMBO)}/>
                    <Text textAlign={'center'}>Комбо</Text>
                </Flex>}
                {!!seasonal?.length && <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}  opacity={selectedSubMenu === SUB_MENU.SEASONAL ? 1: 0.5}>
                    <Image cursor={'pointer'} src={'/images/seasonal.png'} height={50}  onClick={() => setSelectedSubMenu(SUB_MENU.SEASONAL)}/>
                    <Text textAlign={'center'}>Сезонски такос</Text>
                </Flex>}


            </Flex>
            <Flex>
                {selectedSubMenu === SUB_MENU.FOOD &&  <MenuItems handleSelectItem={selectItem} items={food}/> }
                {selectedSubMenu === SUB_MENU.DRINK &&  <MenuItems handleSelectItem={selectItem} items={drink}/>}
                {selectedSubMenu === SUB_MENU.DESSERT &&  <MenuItems handleSelectItem={selectItem} items={dessert}/>}
                {selectedSubMenu === SUB_MENU.COMBO &&  <MenuItems handleSelectItem={selectItem} items={combo}/>}
                {selectedSubMenu === SUB_MENU.SEASONAL &&  <MenuItems handleSelectItem={selectItem} items={seasonal}/>}
            </Flex>
        </Flex>
        <Flex mt={10} justifyContent={'flex-end'}>
            <Button onClick={() => handleCancelOrder()}>Откажи нарачка</Button>
            <Button colorScheme='blue' mx={3} onClick={() => setIsOpen(true)} isDisabled={!order.length}>Види ја нарачката</Button>
        </Flex>
        {!!selectedItem && <ItemEditing item={selectedItem} itemType={selectedItem?.productType || ''} closeModal={clearItem} meat={meat} sauce={sauce} topping={topping} sides={sides} addItemToOrder={addItemToOrder}/>}
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={() => setIsOpen(false)}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Вашата нарачка</DrawerHeader>

                <DrawerBody>
                    <OrderOverview/>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={() => setIsOpen(false)}>
                        Затвори
                    </Button>
                    <Button colorScheme='blue' isDisabled={!order.length} onClick={() => handleConfirmOrder()}>Нарачај</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </Flex>
}