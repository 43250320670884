import {Button, Card, CardBody, CardFooter, CardHeader, Heading, Image, Text} from "@chakra-ui/react";

export default ({text, iconPath, callbackClick}: {text: string, iconPath: string, callbackClick: Function}) => {
    return <Card cursor={'pointer'} onClick={() => callbackClick()}  height={'350px'} width={'300px'} mb={'30px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CardHeader>
            <Image src={iconPath} height={'100px'}/>
        </CardHeader>
        <CardFooter>
            <Heading size='md' textAlign={'center'}>{text}</Heading>
        </CardFooter>
    </Card>
}