import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {getLocations} from "../repository/location";

interface ILocationSlice {
    isLoading: boolean
    data: any[]
    selectedLocation: null | any
    error: boolean
    eatingLocation: null | string
    selectedProduct: null | any
    order: any[],
    creatingOrder: boolean,
    createdOrder: any
}

export const fetchAllLocations = createAsyncThunk(
    'locations/fetchAllLocations',
    async (arg, thunkAPI) => {
        const response = await getLocations()
        return response.data.locations
    }
)

export const createOrder = createAsyncThunk(
    'locations/createOrder',
    async (arg, { getState, rejectWithValue }) => {
        const state = await getState();
        try {
            const response = await getLocations();
            return {
                orderId: '11222-33344'
            }
        } catch (e) {
            return rejectWithValue('Failed')
        }

    }
)

const initialState: ILocationSlice = {
    isLoading: false,
    data: [],
    selectedLocation: null,
    eatingLocation: null,
    error: false,
    selectedProduct: null,
    creatingOrder: false,
    createdOrder: {},
    order: []
}
const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        selectLocation: (state, action) => {state.selectedLocation = action.payload},
        selectEatingLocation: (state, action) => {state.eatingLocation = action.payload},
        selectProduct: (state, action) => {state.selectedProduct = action.payload},
        addProductToOrder: (state, action) => {state.order.push({...action.payload, quantity: 1})},
        changeItemQuantity: (state, action) => {
            const {ind, qty} = action.payload;
            state.order[ind].quantity += qty;
            if(state.order[ind].quantity <= 0) {
                state.order.splice(ind, 1);
            }
        },
        resetOrder: (state, action) => {state.order = []},
        clearProduct: (state) => {state.selectedProduct = null},
        resetState: (state) => initialState
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllLocations.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllLocations.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = action.payload
            })
            .addCase(createOrder.pending, (state, action) => {
                state.creatingOrder = true
                state.error = false
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.creatingOrder = false
                state.error = false
                state.createdOrder = action.payload;

            })
            .addCase(createOrder.rejected, (state, action) => {
                state.creatingOrder = false
                state.error = true
            }),
})

export const { selectLocation , selectEatingLocation, selectProduct, clearProduct, addProductToOrder, resetOrder, resetState, changeItemQuantity} = locationsSlice.actions

export default locationsSlice.reducer
