
import MenuPageView from "./MenuPageView";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {
    addProductToOrder,
    clearProduct,
    resetState,
    selectEatingLocation,
    selectProduct
} from "../../reducers/locations";
import {clear} from "@testing-library/user-event/dist/clear";

export enum ProductType {
    FOOD = 'FOOD',
    TOPPING = 'TOPPING',
    DRINK = 'DRINK',
    MEAT = 'MEAT',
    SAUCE = 'SAUCE',
    COMBO= 'COMBO',
    DESSERT = 'DESSERT',
    SEASONAL= 'SEASONAL',
    SIDE = 'SIDE'
}
export default () => {
    const dispatch = useAppDispatch();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    useEffect(() => {
        if(!locationsState.selectedLocation) {
            navigate('/')
        }
    }, [])

    const handleSelectItem = (item: any) => {
        dispatch(selectProduct(item))
    }
    const handleCancelItem = (item: any) => {
        dispatch(clearProduct())
    }

    const handleAddItemToOrder = (item: any) => {
        dispatch(addProductToOrder(item));
        dispatch(clearProduct())
    }
    const handleConfirmOrder = () => {
        navigate(`/${locationsState.selectedLocation.locationId}/order-info`)
    }

    const handleCancelOrder = () => {
        const locationId = locationsState.selectedLocation.locationId;
        dispatch(resetState())
        navigate(`/${locationId}`)
    }

    const filterProductsByType = (products: any[], type: string) => {
        return products.filter(product => product.productType === type);
    }

    return <MenuPageView food={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.FOOD)}
                         drink={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.DRINK)}
                         meat={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.MEAT)}
                         sauce={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.SAUCE)}
                         topping={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.TOPPING)}
                         dessert={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.DESSERT)}
                         combo={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.COMBO)}
                         seasonal={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.SEASONAL)}
                         sides={filterProductsByType(locationsState.selectedLocation?.menu || [], ProductType.SIDE)}
                         selectItem={handleSelectItem}
                         selectedItem={locationsState.selectedProduct}
                         clearItem={handleCancelItem}
                         addItemToOrder={handleAddItemToOrder}
                         order={locationsState.order}
                         handleConfirmOrder={handleConfirmOrder}
                         handleCancelOrder={handleCancelOrder}
    />
}