import React from "react";
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    Image,
    Input, useToast
} from "@chakra-ui/react";
import EatingLocationCard from "../../components/EatingLocationCard/EatingLocationCard";
import {SubmitHandler, useForm} from "react-hook-form";
import {createOrder} from "../../reducers/locations";
import {useAppDispatch} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";

type Inputs = {
    name: string
    number: string
}
export default ({handleCancelOrder, errorCreatingState}: {handleCancelOrder: Function, errorCreatingState: boolean}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const {id} = useParams();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            await dispatch(createOrder()).unwrap()
            navigate(`/${id}/order-complete`)
        } catch (e) {
            toast({
                title: 'Креирање нарачка.',
                description: "Нарачката не беше креирана. Ве молиме обидете се повторно.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }


    }
    if(errorCreatingState) {

    }

    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'50%'} mx={'auto'}>
        <Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'}  mb={16} />
        <Heading as={'h1'} textAlign={'center'}  mb={32} >Внесете податоци</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction={'column'}>
                <FormControl isInvalid={!!errors.name}>
                    <FormLabel>Име:</FormLabel>
                    <Input {...register("name", { required: true })}/>
                    {!!errors.name && (
                    <FormErrorMessage>Името е задолжително</FormErrorMessage>
                    )}
                </FormControl>
                <FormControl isInvalid={!!errors.number}>
                    <FormLabel>Телефонски број за контакт:</FormLabel>
                    <Input {...register("number", { required: "Телефонскиот број е задолжителен", pattern: { value: /\+\d{11}/, message: 'Грешен формат на телефонски број'} })}/>
                    <FormHelperText>Форматот мора да биде: +38911222333</FormHelperText>
                    {!!errors.number && (
                        <FormErrorMessage>{errors.number.message}</FormErrorMessage>
                    )}
                </FormControl>


                <Button colorScheme={'messenger'} type={"submit"} mt={5}>Креирај нарачка</Button>
                <Button mt={5} onClick={() => handleCancelOrder()}>Почни одново</Button>
            </Flex>
        </form>

    </Flex>
}