import OrderOverviewView from "./OrderOverviewView";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate} from "react-router-dom";
import {resetState, selectEatingLocation} from "../../reducers/locations";

export default () => {
    const dispatch = useAppDispatch();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    const handleCancelOrder = () => {
        dispatch(resetState())
        navigate(`/${locationsState.selectedLocation.locationId}`)
    }
    const handleOverviewConfirm = () => {
        navigate(`/${locationsState.selectedLocation.locationId}/order-info`)
    }
    return <OrderOverviewView order={locationsState.order} handleCancelOrder={handleCancelOrder} handleOverviewConfirm={handleOverviewConfirm}/>
}