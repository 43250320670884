import React from "react";
import {Badge, Flex, Image, Text} from "@chakra-ui/react";

export default ({items, handleSelectItem}: {items: any[], handleSelectItem: Function}) => {
    return <Flex gap={4} w={'full'} flexWrap={'wrap'} cursor={'pointer'}>
        {items.map(item => <Flex direction={'column'} w={'200px'} alignItems={'flex-start'} onClick={() => handleSelectItem(item)}>
            <Image src={item.image} height={'50px'} mx={'auto'}/>
            <Text>{item.name}</Text>
            {!!item.price && <Badge>{item.price} ден.</Badge>}
        </Flex>)}
    </Flex>
}