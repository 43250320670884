import EatingLocationView from "./OrderInfoView";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate, useParams} from "react-router-dom";
import {resetState, selectEatingLocation, selectLocation} from "../../reducers/locations";

export default () => {
    const dispatch = useAppDispatch();
    const {id} = useParams()
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    const handleCancelOrder = (location:string) => {
        dispatch(resetState());
        navigate(`/${id}`)
    }
    return <EatingLocationView handleCancelOrder={handleCancelOrder} errorCreatingState = {locationsState.error}/>
}