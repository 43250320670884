import HomepageView from "./OrderCompleteView";
import {fetchAllLocations, resetState, selectLocation} from "../../reducers/locations";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate} from "react-router-dom";

export const OrderComplete = () => {
    const dispatch = useAppDispatch();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    const handleGoBack = () => {
        const locationId = locationsState.selectedLocation.locationId;
        dispatch(resetState());
        navigate(`/${locationId}`)
    }
    return <HomepageView orderId={locationsState.createdOrder?.orderId} handleGoBack={handleGoBack}/>
}