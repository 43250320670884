import {
    Button, Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Image, Text, Badge, CheckboxIcon
} from "@chakra-ui/react";
import React, {useState, useEffect} from "react";
import {ProductType} from "../../MenuPage";
import {Icon} from "@chakra-ui/icons";

export default ({item,itemType, closeModal, meat, sauce, topping, sides, addItemToOrder}: {item: any, itemType: string, closeModal: Function, meat: any[], sauce: any[], topping: any[], sides: any[], addItemToOrder: Function}) => {
    const [stepForm, setStepForm] = useState(1);
    const [selectedMeat, setSelectedMeat] = useState([] as any[]);
    const [selectedSauces, setSelectedSauces] = useState([] as any[]);
    const [selectedToppings, setSelectedToppings] = useState([] as any[]);
    const [sSides, setSSides] = useState(null as null | any);

    const toggleMeat = (item:any) => {
        const itemFound = selectedMeat.find(sMeat => sMeat.productId === item.productId);
        if(itemFound) {
            setSelectedMeat(selectedMeat.filter(sMeat => sMeat.productId !== item.productId));
        } else {
            setSelectedMeat([...selectedMeat, item]);
        }
    }
    const toggleSauce = (item:any) => {
        const itemFound = selectedSauces.find(sSauces => sSauces.productId === item.productId);
        if(itemFound) {
            setSelectedSauces(selectedSauces.filter(sSauces => sSauces.productId !== item.productId));
        } else {
            setSelectedSauces([...selectedSauces, item]);
        }
    }
    const toggleTopping = (item:any) => {
        const itemFound = selectedToppings.find(sTopping => sTopping.productId === item.productId);
        if(itemFound) {
            setSelectedToppings(selectedToppings.filter(sTopping => sTopping.productId !== item.productId));
        } else {
            setSelectedToppings([...selectedToppings, item]);
        }
    }
    const selectSide = (item: any) => {
        setSSides(item);
    }


    return <Modal closeOnOverlayClick={false} isOpen={!!item} onClose={()=>closeModal()} size={'full'}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Одбери продукт</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <Flex direction={'column'} alignItems={'center'}>
                    <Flex>
                        <Image src={item?.image} h={'300px'}/>
                    </Flex>
                    <Flex>
                        {item?.name}
                    </Flex>
                </Flex>
                {[ProductType.FOOD].includes(item?.productType) && <Flex justifyContent={'center'} flexWrap={'wrap'} mt={5}>
                    {stepForm === 1 && <Flex flexWrap={'wrap'} gap={4} p={4}>
                        {meat?.map(mItem => <EditingItem item={mItem} handleSelectItem={toggleMeat} selected={!!selectedMeat.find(sMeat => sMeat.productId === mItem.productId)}/>)}
                    </Flex>}
                    {stepForm === 2 && <Flex flexWrap={'wrap'} gap={4} p={4}>
                        {sauce?.map(mSauce => <EditingItem item={mSauce} handleSelectItem={toggleSauce} selected={!!selectedSauces.find(sSauce => sSauce.productId === mSauce.productId)}/>)}
                    </Flex>}
                    {stepForm === 3 && <Flex flexWrap={'wrap'} gap={4} p={4}>
                        {topping?.map(tItem => <EditingItem item={tItem} handleSelectItem={toggleTopping} selected={!!selectedToppings.find(sTopping => sTopping.productId === tItem.productId)}/>)}
                    </Flex>}
                    {stepForm === 4 && <Flex flexWrap={'wrap'} gap={4} p={4}>
                        {sides?.map(side => <EditingItem item={side} handleSelectItem={selectSide} selected={sSides?.productId === side.productId}/>)}
                    </Flex>}
                </Flex>}

            </ModalBody>

            <ModalFooter>
                {(item?.productType !== ProductType.FOOD || (item?.productType === ProductType.FOOD && stepForm > 3)) && <>
                    <Button onClick={()=>closeModal()}>Откажи</Button>
                    <Button colorScheme='blue' ml={3} onClick={() => addItemToOrder({
                        ...item,
                        extras: [
                            ...selectedMeat,
                            ...selectedSauces,
                            ...selectedToppings,
                            ...!!sSides ? [sSides] : []
                        ]
                    })} >
                        Додади
                    </Button>

                </>}
                {item?.productType == ProductType.FOOD && stepForm < 4 && <>
                    <Button colorScheme='blue' isDisabled={stepForm == 0} onClick={() => setStepForm(stepForm - 1)}>Претходно</Button>
                    <Button colorScheme='blue' isDisabled={stepForm == 4} ml={3} onClick={() => setStepForm(stepForm + 1)} >
                        Следно
                    </Button>
                </>}

            </ModalFooter>
        </ModalContent>
    </Modal>
}

const EditingItem = ({item, handleSelectItem, selected}: {item: any, handleSelectItem: Function, selected: boolean}) => {

    return <Flex cursor='pointer' direction={'column'} w={'200px'} p={4} alignItems={'flex-start'} onClick={() => handleSelectItem(item)} borderWidth={selected? '1px': '0px'} borderRadius={'4px'} borderColor={'blue'}>
        <Image src={item.image} height={'50px'} mx={'auto'}/>
        <Text>{item.name}</Text>
        {!!item.price && <Badge>{item.price} ден.</Badge>}
    </Flex>
}
